import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SizzleImagesGuard } from './data-guards/sizzle-images-guard';
import { AppGuard } from './component-guards/app.guard';

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		SizzleImagesGuard,
		AppGuard
	]
})

export class GuardsModule { }
