import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IBook } from 'src/app/models/entities/i-book';
import { OuterPaths } from 'src/app/services/route-service';

const descriptionLimit: number = 150;

@Component({
  selector: 'bs-book-tile',
  templateUrl: './book-tile.component.html',
  styleUrls: ['./book-tile.component.less']
})
export class BookTileComponent {
	public hovering: boolean = false;

	private formattedBook: IBook;

	public get amazonLink(): string {
		return `${OuterPaths.amazon}${this.asin}`;
	}

	public get asin(): string {
		return this.formattedBook.asin;
	}

	public get author(): string {
		return this.formattedBook.author;
	}

	@Input()
	public get book(): IBook {
		return this.formattedBook;
	}

	public set book(value: IBook) {
		this.formattedBook = {
			...value,
			description: this.formatDescription(value.description),
			genre: this.formatGenre(value.genre),
			salePrice: this.formatPrice(value.salePrice),
		};
	}

	public get description(): string {
		return this.formattedBook.description;
	}

	public get genre(): string {
		return this.formattedBook.genre;
	}

	public get image(): string {
		return this.formattedBook.image;
	}

	public get isFree(): boolean {
		return this.price === 0;
	}

	public get price(): number {
		return this.formattedBook.salePrice;
	}

	public get tags(): string[] {
		return this.formattedBook.keywords;
	}

	public get title(): string {
		return this.formattedBook.title;
	}

	public constructor() { }

	private formatDescription(description: string): string {
		// Some descriptions for auto-imported books include {IMPORT} in the description as an identifier.
		// We don't want to display this.
		const removedImport: string = description.replace('{IMPORT}', '');
		const trim: string = removedImport.slice(0, descriptionLimit).trim();
		const elipsis: string = removedImport.length > descriptionLimit ? `${trim}…` : trim;

		return elipsis;
	}

	private formatGenre(genre: string): string {
		return genre.replace('_', '');
	}

	private formatPrice(price: number): number {
		return price / 100;
	}
}
