import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'bs-svg',
    template: `
        <svg focusable="false" viewBox="0 0 72 72">
            <use attr.xlink:href="{{ href }}"></use>
        </svg>
    `,
    styles: [`
        bs-svg {
            display: inline-block;
            font-size: 0;
        }

        bs-svg svg {
            stroke: currentColor;
            fill: currentColor;
            width: 100%;
            height: 100%;
            /* Prevents edges from being cut off. */
            padding: 1px;
        }
    `],
    host: {
        class: 'bs-svg'
    },
    encapsulation: ViewEncapsulation.None
})
export class SvgComponent {
    @Input() public href: string;
}
