import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'bs-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.less']
})
export class UnderMaintenanceComponent implements OnInit {

  constructor(private title: Title) { }

  ngOnInit() {
	  this.title.setTitle('bookSCREAM - Under Maintenance');
  }

}
