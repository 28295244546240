import { Injectable } from "@angular/core";
import { Router } from '@angular/router';

export enum RoutePaths {
	landing = '',
	advertise = 'advertise',
}

export enum OuterPaths {
	amazon = 'https://amazon.com/dp/',
}

@Injectable()
export class RouteService {
	public constructor(private router: Router) { }

	public goToAdvertise(): void {
		this.router.navigateByUrl(RoutePaths.advertise);
	}

	public goToLanding(): void {
		this.router.navigateByUrl(RoutePaths.landing);
	}
}
