import {ActionReducerMap, createSelector} from '@ngrx/store';

import * as fromSizzleImages from '../app/services/state/reducers/sizzle-images-reducer';
import * as SizzleImagesSelectors from '../app/services/state/selectors/sizzle-images-selector';

export interface AppState {
	sizzleImages: fromSizzleImages.SizzleImagesState;
}

export const reducers: ActionReducerMap<AppState> = {
	sizzleImages: fromSizzleImages.sizzleImagesReducer
};

export const sizzleImagesState = (state: AppState) => {
	return state.sizzleImages;
};

export const selectors = {
	allSizzleImages: createSelector(sizzleImagesState, SizzleImagesSelectors.allSizzleImages)
};
