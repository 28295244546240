import { Component, OnInit, Input } from '@angular/core';
import { IHighlight } from 'src/app/models/entities/i-highlight';

@Component({
	selector: 'bs-highlights',
	templateUrl: './highlights.component.html',
	styleUrls: ['./highlights.component.less']
})
export class HighlightsComponent implements OnInit {
	@Input()
	public books: IHighlight[] = [];

	constructor() { }

	ngOnInit() {
	}

}
