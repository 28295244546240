import { Action, ActionReducer } from '@ngrx/store';
import * as TransactionActions from '../actions/transaction-action';

export function canRollback(state: any): boolean {
    return state.canRollback;
}

export function transactionReducer(rootReducer: ActionReducer<any>): ActionReducer<any> {
    let irreversibleActions: Array<Action> = [];
    let stateSnapshot;
    let transactionInProgress = false;

    return (state: any, action: Action) => {
        switch (action.type) {
            case TransactionActions.BEGIN_TRANSACTION: {
                transactionInProgress = true;
                irreversibleActions = [];
                stateSnapshot = rootReducer(state, action);

                return {
                    ...stateSnapshot,
                    canRollback: Boolean(stateSnapshot)
                };
            }

            case TransactionActions.COMMIT_TRANSACTION: {
                irreversibleActions = [];
                stateSnapshot = undefined;
                transactionInProgress = false;

                return {
                    ...rootReducer(state, action),
                    canRollback: Boolean(stateSnapshot)
                };
            }

            case TransactionActions.ROLLBACK_TRANSACTION: {
                let newState: any;

                if (transactionInProgress) {
                    newState = stateSnapshot;
                    irreversibleActions.forEach(a => newState = rootReducer(newState, a));
                } else {
                    newState = rootReducer(state, action);
                }

                transactionInProgress = false;
                irreversibleActions = [];
                stateSnapshot = undefined;

                return {
                    ...newState,
                    canRollback: Boolean(stateSnapshot)
                };
            }

            case TransactionActions.CAN_ROLLBACK: {
                return {
                    ...rootReducer(state, (<any>action).payload),
                    canRollback: Boolean(stateSnapshot)
                };
            }

            default: {
                if (transactionInProgress) {
                    irreversibleActions.push(action);
                }

                return {
                    ...rootReducer(state, action),
                    canRollback: Boolean(stateSnapshot)
                };
            }
        }
    };
}
