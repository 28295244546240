import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Endpoint } from '../models/const/endpoints';
import { AppState } from '../app-state';
import { Store } from '@ngrx/store';
import * as SizzleImagesActions from '../services/state/actions/sizzle-images-actions';

export class SizzleImagesService {
	constructor(private httpClient: HttpClient, private store: Store<AppState>) { }

	public getSizzleImages(): Observable<boolean> {
		this.httpClient.get(`${Endpoint.root_ui}/assets/temp-sizzle-images.json`)
      .subscribe((data: Observable<string[]>) => {
        const endpoints: string[] = data[Endpoint.imageUrls];
        this.store.dispatch(new SizzleImagesActions.StoreSizzleImages(endpoints));
        return new Observable<true>();
      });

		return new Observable<false>();
	}
}
