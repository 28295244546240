import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SizzleImagesGuard } from '../data-guards/sizzle-images-guard';
import { Observable, of } from 'rxjs';

@Injectable()
export class AppGuard implements CanActivate {
	constructor(
		private sizzleImagesGuard: SizzleImagesGuard,
	) { }

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		return of(true);
	}
}
