import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
import { NgModule } from '@angular/core';
import { RouterModule, Params, Routes } from '@angular/router';
import { AppGuard } from './shared/guards/component-guards/app.guard';
import { RouterReducerState } from '@ngrx/router-store';
import { LandingComponent } from 'src/app/components/landing/landing.component';
import { RoutePaths } from 'src/app/services/route-service';
import { AdvertiseComponent } from './components/advertise/advertise.component';

export interface RouterStateUrl {
	url: string;
	params: Params;
	queryParams: Params;
}

export interface State {
	router: RouterReducerState<RouterStateUrl>;
}

const routes: Routes = [
	{
		path: RoutePaths.landing,
		component: UnderMaintenanceComponent,
		canActivate: [AppGuard]
	},
	{
		path: RoutePaths.advertise,
		component: AdvertiseComponent,
		canActivate: [AppGuard]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})

export class AppRoutingModule { }
