import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SizzleImagesService } from '../../../services/sizzle-images-service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../../app-state';

@Injectable()
export class SizzleImagesGuard implements CanActivate {
	public constructor(private httpClient: HttpClient, private store: Store<AppState>) {}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const service: SizzleImagesService = new SizzleImagesService(this.httpClient, this.store);
		return service.getSizzleImages();
	}
}
