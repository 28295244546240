import { IBook } from 'src/app/models/entities/i-book';
import { CatalogService } from 'src/app/services/catalog-service';
import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { take } from 'rxjs/operators';

enum AdvertiseStep {
	Email,
	Asin,
	Details,
}

@Component({
	selector: 'bs-advertise',
	templateUrl: './advertise.component.html',
	styleUrls: ['./advertise.component.less']
})
export class AdvertiseComponent {
	public readonly asinFormControl: FormControl = new FormControl('B00CV30X2E', [ Validators.required, Validators.pattern('[Bb][0-9a-zA-Z]{9}') ]);
	public readonly emailFormControl: FormControl = new FormControl(null, [ Validators.required, Validators.email ]);

	private book: IBook;
	private currentStep: AdvertiseStep = AdvertiseStep.Asin;

	public get asin(): string {
		return this.book ? this.book.asin : this.asinFormControl.value;
	}

	public get step1(): boolean {
		return this.currentStep === AdvertiseStep.Email;
	}

	public get step2(): boolean {
		return this.currentStep === AdvertiseStep.Asin;
	}

	public get step3(): boolean {
		return this.currentStep === AdvertiseStep.Details;
	}

	public constructor(
		private catalogService: CatalogService,
	) {}

	public onAddAsin(): void {
		// this.currentStep = AdvertiseStep.Details;
		this.catalogService.fetchBookByAsin(this.asinFormControl.value)
			.pipe(take(1))
			.subscribe((book: IBook) => {
				if (book) {
					this.currentStep = AdvertiseStep.Details;
				}
			})
	}

	public onAddEmail(): void {
		this.currentStep = AdvertiseStep.Asin;
	}
}
