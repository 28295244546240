import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IDeal } from 'src/app/models/entities/i-deal';
import { RouteService } from 'src/app/services/route-service';

@Component({
  selector: 'bs-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.less']
})
export class DealsComponent implements OnInit {
	@Input()
	public books: IDeal[];

	public constructor(private routeService: RouteService) { }

	public ngOnInit() {
	}

}
