import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuardsModule } from './guards/guards.module';

@NgModule({
	imports: [
		CommonModule,
		GuardsModule
	],
	exports: [
		CommonModule,
		GuardsModule
	]
})
export class SharedModule { }
