import { HighlightsComponent } from './components/highlights/highlights.component';
import { CatalogService } from 'src/app/services/catalog-service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { transactionReducer } from '../app/services/state/reducers/transaction-reducer';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './components/app/app.component';
import * as AppState from './app-state';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BookTileComponent } from './components/book-tile/book-tile.component';
import { SubscribePanelComponent } from './components/subscribe-panel/subscribe-panel.component';
import { HttpService } from './services/http.service';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { LandingComponent } from 'src/app/components/landing/landing.component';
import { DealsComponent } from 'src/app/components/deals/deals.component';
import { SvgModule } from 'src/app/components/svg/svg.module';
import { RouteService } from 'src/app/services/route-service';
import { CommonModule } from '@angular/common';
import { AdvertiseComponent } from './components/advertise/advertise.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

export const metaReducers: MetaReducer<any>[] = [transactionReducer];

@NgModule({
	declarations: [
		AppComponent,
		AdvertiseComponent,
		BookTileComponent,
		DealsComponent,
		HighlightsComponent,
		LandingComponent,
		NavbarComponent,
		SubscribePanelComponent,
	],
	imports: [
		BrowserModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		AppRoutingModule,
		SharedModule,
		StoreModule.forRoot(AppState.reducers, {metaReducers}),
		SvgModule,
	],
	providers: [
		CatalogService,
		HttpService,
		RouteService,
	],
	bootstrap: [AppComponent]
})

export class AppModule { }
