import { IBook } from 'src/app/models/entities/i-book';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { IGetHighlightsResponse } from 'src/app/models/responses/get-highlights-response';
import { IGetDealsResponse } from 'src/app/models/responses/get-deals-response';
import { Endpoint } from 'src/app/models/const/endpoints';
import { IGetBookByAsinResponse } from '../models/responses/get-book-by-asin-response';

@Injectable()
export class CatalogService {
	constructor(private httpClient: HttpClient) { }

	public getHighlights(): Observable<IGetHighlightsResponse> {
		return this.httpClient.get(`${Endpoint.root_api}/highlights.php`)
		.pipe(
				switchMap((data: IGetHighlightsResponse) => {
					return of(data);
				}),
			);
	}

	public getTodaysDeals(): Observable<IGetDealsResponse> {
		return this.httpClient.get(`${Endpoint.root_api}/todays-deals-legacy.php`)
			.pipe(
				switchMap((data: IGetDealsResponse) => {
					return of(data);
				}),
			);
	}

	public fetchBookByAsin(asin: string): Observable<IBook> {
		return this.httpClient.post(`${Endpoint.root_api}/book.php`, `{"asin":"${asin}"}`)
			.pipe(
				switchMap((response: IGetBookByAsinResponse) => {
					console.log(response);
					return of(response.details);
				}),
			);
	}
}
