import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpService {
	constructor(private http: HttpClient) {}

	public get(url: string): Observable<HttpResponse<any>> {
		return this.http.get<any>(`${url}`);
	}

	public post(url: string, body: any): Observable<HttpResponse<any>> {
		return this.http.post<any>(`${url}`, body);
	}
}
