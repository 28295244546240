import { Action } from '@ngrx/store';

export const STORE_SIZZLE_IMAGES = '[SizzleImages] Store Sizzle Images';
export class StoreSizzleImages implements Action {
	public readonly type = STORE_SIZZLE_IMAGES;

	constructor(public payload: string[]) { }
}

export type Any
	= StoreSizzleImages;
