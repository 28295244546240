import { IGetDealsResponse } from 'src/app/models/responses/get-deals-response';
import { CatalogService } from 'src/app/services/catalog-service';
import { Component, OnInit } from '@angular/core';
import { ILandingViewModel, landingViewModel } from 'src/app/components/landing/landing.viewmodel';
import { take } from 'rxjs/operators';
import { IGetHighlightsResponse } from 'src/app/models/responses/get-highlights-response';
import { IHighlight } from 'src/app/models/entities/i-highlight';
import { IDeal } from 'src/app/models/entities/i-deal';

@Component({
  selector: 'bs-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.less']
})
export class LandingComponent implements OnInit {
  public get highlightItems(): IHighlight[] {
	return this.viewModel.highlightItems;
  }

  public get showHighlights(): boolean {
	  return this.highlightItems.length > 0;
  }

  public get todaysDeals(): IDeal[] {
	return this.viewModel.todaysDeals;
  }

  private viewModel: ILandingViewModel = landingViewModel;

  public constructor(private catalogService: CatalogService) {
	this.attachViewModel();
  }

  public ngOnInit() {
  }

  private attachViewModel(): void {
	this.catalogService.getHighlights()
		.pipe(take(1))
		.subscribe((data: IGetHighlightsResponse) => {
			this.viewModel.highlightItems = data.highlights;
		}, (error: any) => {
			console.error(error);
		});

	this.catalogService.getTodaysDeals()
		.pipe(take(1))
		.subscribe((data: IGetDealsResponse) => {
			this.viewModel.todaysDeals = data.deals;
		}, (error: any) => {
			console.error(error);
		});
  }
}
