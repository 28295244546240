import { Action } from '@ngrx/store';

export const BEGIN_TRANSACTION = '[Transaction] Begin Transaction';
export class BeginTransaction implements Action {
	public readonly type = BEGIN_TRANSACTION;
}

export const COMMIT_TRANSACTION = '[Transaction] Commit Transaction';
export class CommitTransaction implements Action {
	public readonly type = COMMIT_TRANSACTION;
}

export const ROLLBACK_TRANSACTION = '[Transaction] Rollback Transaction';
export class RollbackTransaction implements Action {
	public readonly type = ROLLBACK_TRANSACTION;
}

export const CAN_ROLLBACK = '[Transaction] Can Rollback';
export class CanRollback implements Action {
	public readonly type = CAN_ROLLBACK;

	constructor(public payload: Action) { }
}
