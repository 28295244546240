import { IHighlight } from 'src/app/models/entities/i-highlight';
import { IDeal } from 'src/app/models/entities/i-deal';

export interface ILandingViewModel {
  highlightItems: IHighlight[];
  todaysDeals: IDeal[],
}

export const landingViewModel: ILandingViewModel = {
  highlightItems: [],
  todaysDeals: [],
};
