import { Component } from '@angular/core';
import { RouteService } from 'src/app/services/route-service';
import { INavbarConfiguration } from 'src/app/components/navbar/i-navbar-configuration';

@Component({
  	selector: 'bs-navbar',
  	templateUrl: './navbar.component.html',
  	styleUrls: ['./navbar.component.less']
})

export class NavbarComponent {
	private configuration: INavbarConfiguration = {
		showAdvertise: false,
		showBrowse: false,
		showContactUs: true,
		showLogin: false,
	};

	public get showAdvertise(): boolean {
		return this.configuration.showAdvertise;
	}

	public get showBrowse(): boolean {
		return this.configuration.showBrowse;
	}

	public get showContactUs(): boolean {
		return this.configuration.showContactUs;
	}

	public get showLogin(): boolean {
		return this.configuration.showLogin;
	}

	public constructor(private routeService: RouteService) { }

	public onAdvertiseClick(): void {
		console.log('advertise');
		this.routeService.goToAdvertise();
	}

	public onBrowseClick(): void {
		console.log('browse');
	}

	public onContactUsClick(): void {
		console.log('contact us');
	}

	public onLogInClick(): void {
		console.log('log in');
	}

	public onLogoClick(): void {
		console.log('logo');
		this.routeService.goToLanding();
	}
}
