import * as SizzleImagesActions from '../actions/sizzle-images-actions';

export interface SizzleImagesState {
	imageUrls: string[];
}

export const initialState: SizzleImagesState = {
	imageUrls: []
};

export function sizzleImagesReducer(state: SizzleImagesState = initialState, action: SizzleImagesActions.Any) {
	switch (action.type) {
		case SizzleImagesActions.STORE_SIZZLE_IMAGES:
			return {
				...state,
				imageUrls: action.payload,
			};
		default: {
			return state;
		}
	}
}

